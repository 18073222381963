import gsap from "gsap";

export default class Accordion {
  constructor(el, params) {
    this.el = el;

    this.open = false;
    this.isAnimating = false;

    this.bindMethods()
    this.addEvents()
    this.getElems()

    if(params.has(this.el.name)) this.onClick()
  }

  bindMethods() {
    this.toggle = this.toggle.bind(this)
    this.onClick = this.onClick.bind(this)
  }

  addEvents() {
    this.el.addEventListener('click', this.onClick)
  }

  getElems() {
    this.content = this.el.querySelector('.playlist__content')
    this.items = this.el.querySelectorAll('.playlist__item')
    this.links = this.el.querySelectorAll('.playlist__item__link')
    this.new = this.el.querySelector('.playlist__new')
  }

  onClick() {
    const height = this.open ? 0 : 'auto'
    const pointerEvents = this.open ? 'none' : 'auto'

    if(this.isAnimating) return

    this.open = !this.open

    this.toggle(height, pointerEvents)

  }

  toggle(height, pointerEvents) {
    this.tl = gsap.timeline({
      onStart: () => {
        this.isAnimating = true;
      }, 
      onComplete: () => {
        this.isAnimating = false;
      }
    })

    if(this.new) {
      this.tl.to(this.new, {
        duration: 0.66,
        yPercent: !this.open ? 0 : -40,
        ease: 'power2.inOut',
      }, !this.open ? 'start+=0.45' : 'start')
    }

    this.tl.to(this.links, {
      duration: 0.66,
      pointerEvents
    }, 'start')

    this.tl.to(this.links, {
      duration: 0.66,
      pointerEvents
    }, 'start')

    this.tl.to(this.content, {
      height, duration: 0.66,
      pointerEvents,
      ease: 'power2.inOut',
    }, !this.open ? 'start+=0.45' : 'start')

    this.tl.to(this.items, {
      duration: 0.66,
      opacity: !this.open ? 0 : 1,
      ease: 'power2.inOut',
      stagger: {
        each: 0.2,
        from: !this.open ? 'end' : 'start',
        ease: 'power2.inOut',
      }
    }, !this.open ? 'start' : 'start+=0.25')
  }
}