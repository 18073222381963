import {
  Scene,
  Cache,
  WebGLRenderer,
  PerspectiveCamera,
  CameraHelper,
  DirectionalLight,
  sRGBEncoding,
  Group,
  AmbientLight,
  Vector2,
  MathUtils
} from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import Loader from '../utils/Loader'

/**
 * A class used to initialize everything needed to start a WebGL context across the app.
 *
 * @param {Object} props - An object containing parameters properties.
 * @param {Boolean} props.debug - A boolean defining whether the webgl should be in debug mode or not (activating gui, OrbitControls and some other helpers).
 * @param {Object} props.rendererOptions - An object with all the options that will be passed to the `WebGLRenderer`.
 *
 * @class
 */
export default class WebGLManager {
  constructor(container, sizes, pane) {
    this.container = container;
    this.sizes = sizes;
    this.isRendering = false
    this.isReady = false
    this.isVisible = false
    this.loaded = false;
    this.time = 0;
    this.deltaTime = 0;
    this.onIntersect = this.onIntersect.bind(this)

    this.db = new Vector2(this.sizes.x, this.sizes.x)

    this.isAnimating = false

    WebGLManager.instance = this
    WebGLManager.isOrbit = false

    WebGLManager.params = null;

    Cache.enabled = true

    this.createRenderer()
    this.createScene()
    this.createCamera()
    this.createLights()
    
    this.currentCamera = this.camera
    WebGLManager.camera = this.currentCamera
    this.initPostProc();
  }

  /**
   * Creates the `THREE.WebGLRenderer`.
   *
   * Adds the canvas to the document and watches it's visiblity.
   *
   * @param {Object} options An object that will be passed to the `WebGLRenderer` constructor.
   *
   * @returns {void}
   */
  createRenderer() {
    this.renderer = new WebGLRenderer({
      canvas: this.container,
      powerPreference: 'high-performance',
      antialias: true,
      alpha: true,
    })

    this.renderer.setSize(this.sizes.x, this.sizes.y)
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

    this.container.append(window.body)

    this.observer = new IntersectionObserver(this.onIntersect, {
      rootMargin: '0px',
      threshold: 0
    })

    this.observer.observe(this.renderer.domElement)
  }

  /**
   * Called whenever the canvas goes inside or outside of the viewport.
   *
   * Changes the value `this.isVisible` accordingly.
   *
   * @param {Array<IntersectionObserverEntry>} entries - The related entries.
   * @param {String} observer - The IntersectionObserver instance.
   *
   * @returns {void}
   */
  onIntersect(entries, observer) {
    if (!entries.length) return

    this.isVisible = entries[0].isIntersecting
  }

  /**
   * Creates a new `THREE.Scene`.
   *
   * @returns {void}
   */
  createScene() {
    this.scene = new Scene()
  }

  /**
   * Creates a new `THREE.PerspectiveCamera` and adds it to the Scene.
   *
   * @returns {void}
   */
  createCamera() {
    this.camera = new PerspectiveCamera(65, this.sizes.x / this.sizes.y, 0.1, 100000)

    this.controls = new OrbitControls(this.camera, this.container)

    this.cameraHelper = new CameraHelper(this.camera)

    this.cameraHelper.visible = false

    this.camera.position.z = 3
    WebGLManager.unitSize = WebGLManager.getUnitSize()

    this.groupCamera = new Group();
    WebGLManager.groupCamera = this.groupCamera;

    this.groupCamera.add(this.camera);
    this.groupCamera.add(this.cameraHelper);

    this.scene.add(this.groupCamera)
    // this.scene.add(this.cameraHelper)
  }

  static getUnitSize(depth = 0) {
    const cam = WebGLManager.getCamera()
    const offset = cam.position.z
    let d = depth

    if (depth < offset) d -= offset
    else d += offset

    const vFOV = (cam.fov * Math.PI) / 180 // vertical fov in radians

    // Math.abs to ensure the result is always positive
    const height = 2 * Math.tan(vFOV / 2) * Math.abs(d)
    const width = height * cam.aspect

    return {
      height,
      width
    }
  }

  static getViewSizeAtDepth(depth = 0) {
    const cam = WebGLManager.getCamera()
    const fovInRadians = (cam.fov * Math.PI) / 180;
    const height = Math.abs(
      (cam.position.z - depth) * Math.tan(fovInRadians / 2) * 2
    );
    return { width: height * cam.aspect, height };
  }

  /**
   * Creates an object with some lights and adds them to the Scene.
   *
   * @returns {void}
   */
  createLights() {
    this.lights = {
      directional: new DirectionalLight(0xffffff, 1)
    }

    // scene.add( spotLight );

    const ambientLight = new AmbientLight( 0xD9D9D9, 1.25 );

    this.scene.add(ambientLight)
  }

  /**
   * A method to call when the WebGL context is created and ready to run.
   *
   * Sets `this.isReady = true`.
   *
   * Immediately forces a render of the scene with the active Camera.
   *
   * Called by `App` in its `start()` method.
   *
   * @returns {void}
   */
  ready() {
    this.isReady = true

    this.renderer.render(this.scene, this.currentCamera)
  }

  /**
 * Disable Controls of the camera.
 *
 * @static
 * @returns {void}
 */
  static disabledControl() {
    if (this.instance.controls) {
      this.instance.controls.enabled = false;
    } 
  }

  /**
   * Enable Controls of the camera.
   *
   * @static
   * @returns {void}
   */
  static enabledControl() {
    if (this.instance.controls) this.instance.controls.enabled = true;
  }
  
  /**
   * Adds objects to the scene.
   *
   * @param  {...Object3D} objects The Object3D instances you want to add to the scene.
   *
   * @static
   * @returns {void}
   */
  static addToScene(...objects) {
    if (objects) WebGLManager.instance.scene.add(...objects)
  }

  /**
   * Adds an object to the Camera.
   *
   * @param {...Object3D} objects The Object3D instances to add to the Camera.
   *
   * @static
   * @returns {void}
   */
  static addToCamera(...objects) {
    if (objects) WebGLManager.instance.camera.add(...objects)
  }

  /**
   * Removes objects from the scene.
   *
   * @param {(Object3D|Array<Object3D>)} objects The Object3D that will be removed from the scene.
   *
   * @static
   * @returns {void}
   */
  static removeFromScene(objects) {
    if (Array.isArray(objects)) {
      for (const obj of objects) {
        WebGLManager.removeObject(obj)
      }
    } else WebGLManager.removeObject(objects)
  }

  /**
   * Removes a single object from the scene.
   *
   * @param {Object3D} obj The Object3D that will be removed from the scene.
   *
   * @static
   * @returns {void}
   */
  static removeObject(obj) {
    if (!obj) return

    WebGLManager.instance.scene.remove(obj)

    obj.traverse((child) => {
      if (child.geometry) child.geometry.dispose()
      if (child.material) child.material.dispose()
    })
  }

    /**
   * Gets the canvas DOM element.
   *
   * @static
   * @returns {Element} The canvas.
   */
    static getCanvas() {
      return WebGLManager.instance.renderer.domElement
    }

  /**
   * Gets the main Camera.
   *
   * @static
   * @returns {Camera} The Camera.
   */
  static getCamera() {
    return WebGLManager.instance.camera
  }

  /**
   * Forces a new render of the Scene using the current Camera.
   *
   * @static
   * @returns {void}
   */
  static render() {
    WebGLManager.instance.renderer.render(WebGLManager.instance.scene, WebGLManager.instance.currentCamera)
  }

  /**
   * Returns the instance's `isRendering` property.
   *
   * @returns {Boolean} Whether the context is rendering or not.
   */
  static isRendering() {
    return WebGLManager.instance.isRendering
  }

  static isReady(value) {
    WebGLManager.instance.isReady = value
  }

  initPostProc() {
    const renderScene = new RenderPass(WebGLManager.instance.scene, WebGLManager.instance.camera);
    this.composer = new EffectComposer(WebGLManager.instance.renderer);
    WebGLManager.composer = this.composer;
    WebGLManager.renderScene = renderScene;
    this.composer.addPass(renderScene);
  }

  static prepare(page) {
    return new Promise((resolve) => {
      const promises = []

      if (this.loaded) {
        promises.push(Promise.resolve())
      } else {
        if(page.modelsToLoad) promises.push(Loader.loadGLTF(page.modelsToLoad, true))
        if(page.texturesToLoad) promises.push(Loader.loadTexture(page.texturesToLoad))
        if(page.hdrsToLoad) promises.push(Loader.loadHDR(page.hdrsToLoad))
        if(page.KTX2LoaderToLoad) promises.push(Loader.loadKTX2(page.KTX2LoaderToLoad, WebGLManager.instance.renderer))
      }

      Promise.all(promises)
        .then((results) => {


          this.loaded = true

          if (!page.loadedModels) page.loadedModels = results[0]
          if (!page.loadedTextures) page.loadedTextures = results[1]
          if (!page.loadedHDR) page.loadedHDR = results[2]
          if (!page.loadedKTX2) page.loadedKTX2 = results[3]

          // Change texture encoding to sRGBEncoding (taken from Don McDurdy GLTF Viewer)
          // https://github.com/donmccurdy/three-gltf-viewer/blob/7170005928c520b3a934bc7e4b653efaae1132bd/src/viewer.js#L394-L396
          if (page.loadedModels) {
            for (const model of page.loadedModels) {
              const scene = model.scene || model.scenes[0]

              if (scene) {
                scene.traverse((child) => {
                  if (child.material) {
                    if (child.material.map) child.material.map.encoding = sRGBEncoding
                    if (child.material.emissiveMap) child.material.emissiveMap.encoding = sRGBEncoding
                    if (child.material.map || child.material.emissiveMap) child.material.needsUpdate = true
                  }
                })
              }
            }
          }
          
          return {
            textures: page.loadedTextures,
            models: page.loadedModels,
            hdr: page.loadedHDR,
            KTX2: page.loadedKTX2,
          }
          
        })
        .then(page.ready.bind(page))
        .then(resolve)
    })
  }

  /**
   * Renders a new frame only if **ALL** of the following conditions are **true** :
   *
   * - `this.isReady === true` - The context is ready.
   * - `this.isVisible === true` - The canvas is visible on the viewport.
   *
   *
   * @param {Object} params - An object containing two properties related to the elapsed time.
   * @param {Number} params.time - The elapsed time.
   * @param {Number} params.deltaTime - The difference of time between the previous frame and the current.
   *
   * @returns {void}
   */
  render({ time, deltaTime }) {

    if(!this.isReady) return
    this.isRendering = true

    this.time = time;
    this.deltaTime = deltaTime

    if(this.controls && this.controls.enabled) this.controls.update();
    if(!this.composer) this.renderer.render(this.scene, this.currentCamera)
    else this.composer.render()

  }

  /**
   * Called when the browser is resized.
   *
   * Adapts the Camera aspects and set a new size for the Renderer.
   *
   * Called by `App` (this method is throttled and called every 200ms).
   *
   * @returns {void}
   */
  resize() {
    this.camera.aspect = this.sizes.x / this.sizes.y
    WebGLManager.unitSize = WebGLManager.getUnitSize()

    this.renderer.getDrawingBufferSize(new Vector2(this.sizes.x, this.sizes.y))

    this.camera.aspect = this.sizes.x / this.sizes.y
    
    this.camera.updateProjectionMatrix()
    this.renderer.setPixelRatio(this.devicePixelRatio)
    this.renderer.setSize(this.sizes.x, this.sizes.y)

  }

  /**
   * Turns on the debug mode (shows debug info, creates debug camera and orbit controls, debug lights and GUI).
   *
   * Called by the `constructor` if its `debug` parameter property is set to `true`.
   *
   * @returns {void}
   */
  debugMode(pane) {
    this.createDebugCamera()
    this.createDebugGUI(pane)
  }

  /**
   * Creates an `THREE.OrbitControls` using a new `THREE.PerspectiveCamera`.
   *
   * Adds a new `THREE.CameraHelper` to the Scene.
   *
   * @returns {void}
   */
  createDebugCamera() {
    this.debugCamera = new PerspectiveCamera(45, this.sizes.x / this.sizes.y, 0.1, 10000)
    this.cameraHelper = new CameraHelper(this.camera)

    this.debugCamera.position.set(0, 0, 3)

    this.cameraHelper.visible = false
    this.controls.enabled = false

    this.scene.add(this.cameraHelper)
  }

  /**
   * Creates the global GUI instance that will be used by every PageGL instance if the debug mode is on.
   *
   * The default GUI contains some Camera parameters (position, resetting, orbit, helpers).
   *
   * @returns {void}
   */
  createDebugGUI(pane) {
    this.gui = pane.addFolder({ title: 'WebGLManager' })

    const params = {
      orbit: this.controls.enabled,
      helpers: this.cameraHelper.visible
    }

    this.gui.addBinding(this.renderer.info.render, 'calls', {
      readonly: true, label: 'drawcalls', format: (v) => Math.round(v)
    })
    this.gui.addBinding(this.renderer.info.memory, 'geometries', { readonly: true, format: (v) => Math.round(v) })
    this.gui.addBinding(this.renderer.info.memory, 'textures', { readonly: true, format: (v) => Math.round(v) })
    this.gui.addBinding(this.renderer.info.programs, 'length', { readonly: true, label: 'programs' })
    this.gui.addBinding(this, 'isRendering', { readonly: true, label: 'running ?' })

    const cF = this.gui.addFolder({
      title: 'Cameras', expanded: false
    })

    const posCam = cF.addBinding(this.camera, 'position', {
      x: {
        min: -100, max: 100
      }, y: {
        min: -100, max: 100
      }, z: {
        min: -100, max: 100
      }
    })

    const rotCam = cF.addBinding(this.camera, 'rotation', {
      x: {
        min: -180, max: 180
      }, y: {
        min: -180, max: 180
      }, z: {
        min: -180, max: 180
      }
    }).on('change', ({ value }) => {
      this.camera.rotation.x = MathUtils.degToRad(value.x)
      this.camera.rotation.y = MathUtils.degToRad(value.y)
      this.camera.rotation.z = MathUtils.degToRad(value.z)
    })

    const helpersGUI = cF.addBinding(this.cameraHelper, 'visible', { label: 'helpers ?' })

    cF.addBinding(this.camera, 'near', {
      min: 0.1, max: 500
    })
      .on('change', () => {
        this.camera.updateProjectionMatrix()
        this.cameraHelper.update()
      })
    cF.addBinding(this.camera, 'far', {
      min: 0, max: 2000000
    })
      .on('change', () => {
        this.camera.updateProjectionMatrix()
        this.cameraHelper.update()
      })
    cF.addButton({ title: 'Reset Camera' })
      .on('click', () => {
        this.camera.position.set(0, 0, 3)
        this.camera.rotation.set(0, 0, 0)

        posCam.refresh();
        rotCam.refresh();

      })


    const orbits = cF.addBinding(params, 'orbit')
      .on('change', ({ value }) => {
        this.currentCamera = value ? this.debugCamera : this.camera
        this.controls.enabled = value

        WebGLManager.isOrbit = value

        this.cameraHelper.visible = value
        params.helpers = value
        helpersGUI.refresh()
        document.documentElement.classList.toggle('locked', value)
      })

    cF.addButton({ title: 'Reset Orbit' })
      .on('click', () => {
        this.debugCamera.position.set(0, 0, 17)
        this.debugCamera.rotation.set(0, 0, 0)

        this.controls.enabled = false
        WebGLManager.isOrbit = false
        params.orbit = false;

        orbits.refresh();

        this.controls.update()
      })
  }

    /**
   * Logs the webgl information in the console.
   *
   * Can be call via `window.DEV.getDebugInfo()`.
   *
   * @returns {void}
   */
    getDebugInfo() {
      console.table({
        'Draw calls': this.renderer.info.render.calls,
        'Geometries': this.renderer.info.memory.geometries,
        'Textures': this.renderer.info.memory.textures,
        'Programs': this.renderer.info.programs.length,
        'Raf running ?': this.isRendering,
        'Is ready ?': this.isReady,
        'Is visible ?': this.isVisible,
      })
    }
}
