import '../sass/index.scss';
import { Pane } from 'tweakpane';
import * as EssentialPlugin from '@tweakpane/plugin-essentials';
import WebGLManager from './manager/WebGLManager.js';
import { map, damp, format } from "./utils/utilsFunc.js";
import Page from './engine/Page.js';
import Lenis from 'lenis';

const pane = new Pane();
pane.registerPlugin(EssentialPlugin)

export default class App {
  constructor(options){
    this.container = options.dom;
    this.lenis = new Lenis()

    this.startingTime = 0;
    this.lastTime = 0;
    this.timeLoop = 0;
    this.timeLoopMs = 0
    this.deltaTimeLoop = 0;

    this.stop = false;

    this.global = null;

    this.debugBoundsGUI = null

    this.timePaused = true;
    this.isFinished = false;

    this.isLoaded = false;
    this.finishFakeLoading = false
    this.fakeLoader = false;

    this.size = {
      x: window.innerWidth,
      y: window.innerHeight
    }

    this.ratioScreen = this.size.x / this.size.y
    this.devicePixelRatio = window.devicePixelRatio;

    // this.WebGLManager = new WebGLManager(this.container, this.size, pane)
    this.pageGL = new Page({parent: this, size: this.size})


    this.debuggerBool = false

    this.progressCurrent = 0;
    this.newProgress = 0

    this.showedGui = true;

    this.isDebug = false;

    if(this.isDebug) {
      this.fpsGraph = pane.addBlade({
        view: 'fpsgraph', label: 'FPS', lineCount: 2
      })
    } 
    

    this.params = {
      global: {
      },
    }

    this.isMobile = window.matchMedia('(pointer: coarse)').matches
    const promises = []

    Promise.all(promises)
    .then(() => {
      this.paramsGlobal = this.params.global;

      this.bindMethods();
      this.getElems();
      if(!this.isDebug) this.guiBase.remove()
      this.init(options)
      this.events()


      this.render();
      this.debug()
      })

    
  }

  getElems() {
    this.guiBase = document.querySelector('.tp-dfwv')
    this.GUITweapane = document.querySelector('.tp-dfwv');
    this.mobileDisplayGUI = document.querySelector('.js-touch-gui')
    if(this.mobileDisplayGUI) this.mobileDisplayGUISpan = this.mobileDisplayGUI.querySelector('span')
  }

  bindMethods() {
    this.render = this.render.bind(this)
    this.resize = this.resize.bind(this)
    this.keyEvent = this.keyEvent.bind(this)
    this.displayGui = this.displayGui.bind(this)
  }

  events() {
    window.addEventListener('resize', this.resize)
    window.addEventListener('orientationchange', this.resize)

    document.addEventListener('keydown', this.keyEvent)

    if(this.mobileDisplayGUI) this.mobileDisplayGUI.addEventListener('click', this.displayGui)
  }

  keyEvent(e) {
    if(e.key === 't') {
      this.debuggerBool = !this.debuggerBool

      this.changeGUI(this.debuggerBool)
    }
  }

  displayGui() {
    this.mobileDisplayGUISpan.innerText = this.showedGui ? 'Hide' : 'Show';

    this.changeGUI(this.showedGui)

    this.showedGui = !this.showedGui
  }

  changeGUI(bool) {
    this.GUITweapane.style.display = bool ? 'block' : 'none';
  }

  init(options) {
    const promises = []

    this.changeGUI(!this.isMobile)

    promises.push(this.pageGL.init(), this.pageGL.initAssets())

    if(this.fakeLoader) {
      this.panelProgress = document.querySelector('.js-loading')
      this.progressSpan = this.panelProgress.querySelector('span')
      this.counter = this.panelProgress.querySelector('.counter')
    } else {
      this.panelProgress = document.querySelector('.js-loading')

      if(this.panelProgress) this.panelProgress.remove();
    }


    Promise.all(promises)
    .then(() => {
      this.isLoaded = true;
      this.resize();
    });
  }

  updateAnimeFakeLoader(prog) {
    this.progressSpan.style.transform = 'scaleX(' + prog +')';
    this.size.x > 1024 ? this.counter.style.transform = 'scale(' + (prog * 10) +')' :
    this.counter.style.transform = 'scale(' + (prog * 5) +')';
  }

  hideOverlay() {
    this.panelProgress.style.transform = 'translateY(-100%)';
    this.progressSpan.style.opacity = 0;
    this.counter.style.opacity = 0;
    this.finishFakeLoading = true


    setTimeout(() => {
      this.panelProgress.style.display = 'none'
    }, 1000)
  }

  resize(){
    this.devicePixelRatio = window.devicePixelRatio;
    this.size.x = window.innerWidth;
    this.size.y = window.innerHeight;    

    if (this.WebGLManager) this.WebGLManager.resize();

    this.pageGL.resize(this.size, this.devicePixelRatio)
  }

  updateFakeLoading(dt) {
		this.newProgress = damp(0, 1, 0.25, dt);
		if (this.newProgress > 0.99) this.newProgress = 1;
		this.progressCurrent = this.newProgress;

		const num = Math.floor(this.progressCurrent * 100).toString().padStart(3, '0');
		this.counter.textContent = num;

    if(this.newProgress === 1) {
      this.hideOverlay()
    } else {
      this.updateAnimeFakeLoader(this.newProgress)
    }
  }

  render(currentTime){
    if(!this.startingTime) this.startingTime = currentTime;
    if(!this.lastTime) this.lastTime = currentTime;
    this.timeLoopMs = (currentTime-this.startingTime);
    this.timeLoop = this.timeLoopMs / 1000;
    this.deltaTimeLoop = (currentTime-this.lastTime) / 1000;
    this.lastTime = currentTime;

    this.lenis.raf(currentTime)


    const time = this.timeLoop;
    const deltaTime = this.deltaTimeLoop;

    window.requestAnimationFrame(this.render)
    if(this.isDebug) this.fpsGraph.begin()

    if(!this.finishFakeLoading && this.fakeLoader) {
      this.updateFakeLoading(this.timeLoopMs)
    }


    if(!this.isLoaded) return;
    if(this.WebGLManager) {
      this.WebGLManager.render({
        time,
        deltaTime
      })
    }
    this.pageGL.update({
      time,
      deltaTime
    })
    if(this.isDebug) this.fpsGraph.end()    
    
  }

  debug() {
    if(!this.isDebug) return;
    this.global = pane.addFolder({
      title: 'Global',
      expanded: false
    });

    if(this.WebGLManager) WebGLManager.instance.debugMode(pane)

    this.pageGL.debugMode(pane)
  }

}

new App({
  dom: document.querySelector('.app')
});