import {
  Mesh,
  BoxGeometry,
  Vector2,
  MeshNormalMaterial,
} from 'three'
import WebGLManager from '../manager/WebGLManager'
import { map } from "../utils/utilsFunc";

import Loader from '../utils/Loader'
import Accordion from '../components/Accordion';
import playlistData from '../../content/data';
export default class Page {
  constructor({ parent, size }){

    console.log('Refresh the page for new BIRDS !')

    this.parent = parent;
    this.size = {
      x: size.x,
      y: size.y
    }    
    this.loaded = false

    if(this.WebGLManager) this.WebGLManager = WebGLManager;
    
    this.texturesToLoad = []
    this.modelsToLoad = []
    this.hdrsToLoad = []
    this.KTX2LoaderToLoad = []
    this.ratioScreen = this.size.x / this.size.y

    this.db = new Vector2(window.innerWidth, window.innerHeight)

    this.params = {
      enableRotation: true
    }

    // this.initData()

    this.bindMethods()
    this.getElems()

    this.initData()

    this.searchParams = new URLSearchParams(window.location.search);

    const firstRandom = this.getRandomInt(11) + 1
    const secondRandom = this.getRandomInt(11) + 1

    if(this.birdTop.classList[1]) this.birdTop.classList.remove(this.birdTop.classList[1])
    if(this.birdBottom.classList[1]) this.birdBottom.classList.remove(this.birdBottom.classList[1])

    this.birdTop.classList.add('bird-' + firstRandom)
    this.birdBottom.classList.add('bird-' + secondRandom + '-reverse')

    console.log(this.accordionEl)

    for (let i = 0; i < this.accordionEl.length; i++) {
      this.accordion =  new Accordion(this.accordionEl[i], this.searchParams)
    }
  }

  initData() {
    const { accordionEl, playlistContainer, playlistContainerPlaylist, playlistContainerMix, playlistAppendDiv, mixAppendDiv } = this


    if(import.meta.env.MODE === 'playlist') {
      playlistContainerMix.remove()
    } else if(import.meta.env.MODE === 'mix') {
      playlistContainerPlaylist.remove()
    }


    for(let i = 0; i< playlistData.playlist.length; i++){
      if(import.meta.env.MODE === 'playlist' && playlistData.playlist[i].type === 'mix') continue

      if(import.meta.env.MODE === 'mix' && playlistData.playlist[i].type === 'playlist') continue

      const temp = accordionEl.cloneNode(true)
      temp.name = playlistData.playlist[i].nameParams
      temp.type = playlistData.playlist[i].type
      const content = temp.querySelector('.playlist__content')
      const item = temp.querySelector('.playlist__item')
      const tempItem = item.cloneNode(true)

      content.removeChild(item)

      const isMonthly = playlistData.playlist[i].isMonthly

      const tempCover = temp.querySelector('.playlist__cover')

      tempCover.children[0].src = playlistData.playlist[i].cover

      temp.querySelector('.playlist__title').textContent = playlistData.playlist[i].name

      if(playlistData.playlist[i].date) {
        const date = new Date();
        const newDate = playlistData.playlist[i].date;

        newDate.setDate(newDate.getDate() + 5);

        if(date < newDate) {
          temp.querySelector('.playlist__new').style.display = 'flex';
        } 
      }
      

      for(let j = 0; j< playlistData.playlist[i].data.length; j++){
        const cloneTempItem = tempItem.cloneNode(true)
        cloneTempItem.querySelector('.playlist__item__link').href = playlistData.playlist[i].data[j].link
        if(!isMonthly) cloneTempItem.querySelector('.playlist__item__cover').src = playlistData.playlist[i].data[j].picture
        else cloneTempItem.querySelector('.playlist__item__cover').remove()
        if(playlistData.playlist[i].data[j]) {
          cloneTempItem.querySelector('.playlist__item__title').textContent = playlistData.playlist[i].data[j].name
        } else cloneTempItem.querySelector('.playlist__item__title').textContent = playlistData.playlist[i].name + ' ' + playlistData.playlist[i].data[j].name
        if(playlistData.playlist[i].data[j]) {
          cloneTempItem.querySelector('.playlist__item__plateform').classList.remove(cloneTempItem.querySelector('.playlist__item__plateform').classList[1])
          cloneTempItem.querySelector('.playlist__item__plateform').classList.add(playlistData.playlist[i].data[j].plateform)


        } else cloneTempItem.querySelector('.playlist__item__plateform').classList.add(playlistData.playlist[i].data[j].plateform)
        content.appendChild(cloneTempItem)
      }

      if(temp.type === 'mix') {  
        mixAppendDiv.appendChild(temp)
      } else if(temp.type === 'playlist') {
        playlistAppendDiv.appendChild(temp)
      } 

      const spaceEl = document.createElement('span')
      spaceEl.classList.add('playlist__offset')

      temp.appendChild(spaceEl)
    }

    if(accordionEl) accordionEl.parentElement.removeChild(accordionEl)

    this.accordionEl = document.querySelectorAll('.playlist-el');
  }

  bindMethods() {
    this.update = this.update.bind(this)
    this.resize = this.resize.bind(this)
  }

  
  getElems() {
    this.accordionEl = document.querySelectorAll('.playlist-el');
    this.birdTop = document.querySelector('.bird-top')
    this.birdBottom = document.querySelector('.bird-bottom')

    this.playlistContainer = document.querySelector('.playlist-container-base');
    this.playlistContainerPlaylist = document.querySelector('.playlist-container-playlist');
    this.playlistContainerMix = document.querySelector('.playlist-container-mix');

    this.playlistAppendDiv = this.playlistContainerPlaylist.querySelector('.playlist-container');
    this.mixAppendDiv = this.playlistContainerMix.querySelector('.playlist-container');

    this.accordionEl = document.querySelector('.playlist-el');
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }


  init() {
    return new Promise((resolve, reject) => {
      const promises = []


      Promise.all(promises)
      .then(() => {
        resolve();
      })
    });
  }

  initAssets() {
    return new Promise((resolve, reject) => {
      const promisesAssets = []

      if(this.WebGLManager) promisesAssets.push(WebGLManager.prepare(this))

      

      Promise.all(promisesAssets)
      .then(() => {
        if(this.WebGLManager) this.WebGLManager.isReady(true)
      })
      .then(() => {
        resolve();
      })
    });
  }

  ready({textures, models, hdr, KTX2}) {
  }

  resize(size){  
    this.size = {
      x: size.x,
      y: size.y
    }
    this.ratioScreen = this.size.x / this.size.y
  }

  debugMode(pane) {
    const three = pane.addFolder({
      title: 'Three',
    });

    three.addBinding(this.params, 'enableRotation', {
    }).on('change', ({ value }) => {
      this.params.enableRotation = value
    })
  }

  update({ time, deltaTime }) {

  }
}